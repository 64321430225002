import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledProtectionDddosCloudBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledProtectionDddosCloud = css`
  ${styledProtectionDddosCloudBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5Bps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [40],
    marginBottom: [30],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const contentListBps = css(
  mq({
    marginLeft: [0, 0, 118, 220],
    marginRight: [0, 0, 106, 160]
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const img1Bps = css(
  mq({
    maxWidth: [328, 422, 680, 680],
    marginTop: [30, 16, 30, 30],
    marginBottom: [30]
  })
)

export const img2Bps = css(
  mq({
    maxWidth: [328, 422, 504, 504]
  })
)

export const PContentBps = css(
  mq({
    maxWidth: [242, 336, 336, 336]
  })
)

export const contentItemBps = css(
  mq({
    marginBottom: [30, 22, 22, 22]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
  }
  img {
    ${img1Bps};
  }
`

export const globalCloud = css`
  background: #f3f5f5;
  display: flex;
  text-align: center;
  h5{
    ${H5Bps};
    color: rgba(0, 0, 0, 0.87);
  }
  img {
    ${img2Bps};
    margin: 0;
  }
`

export const contentList = css`
  ${contentListBps};
`

export const contentIntems = css`
  display: flex;
  flex-direction: column;
`

export const contentIntem = css`
  display: flex;
  align-items: center;
  ${contentItemBps};
  p {
    ${PContentBps};
  }
`

export const contentCircle = css`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f3f5f5;
  border: 2px solid #fff;
  box-shadow: 0 0 0 4px #f3f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23.7px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 16px;
`