import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import Ilustration1 from '../images/680x284.png'
import Ilustration2 from '../images/504x520.png'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledProtectionDddosCloud,
  header,
  contentList,
  contentIntems,
  contentIntem,
  contentCircle,
  globalCloud
} from '../components/styles/ProtectionDDOSCloud.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledProtectionDddosCloud}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>PROTEÇÃO DDOS cloud</span>
                Proteção e Segurança<br /> Global Cloud Hostdime
              </H4>
              <Paragraph>A Proteção DDoS Cloud da HostDime funciona como um serviço de roteamento de IPs. Todos os tráfegos serão analisados e filtrados, então o tráfego limpo é encaminhado para a nossa rede Premium através dos nossos quatro túneis de encapsulamento Point-to-Point e roteamento (GRE). Essa “nuvem de segurança” utiliza um link privado entre nodes de rede e atua como uma rede de segurança contra ataques DDoS de grandes proporções. Com essa proteção, sua infraestrutura permanecerá protegida, incluindo seus sites, e-mails e FTP.</Paragraph>
              <img src={Ilustration1} />
            </Column>
          </Row>
          <Row
            flexDirection={['column', 'column', 'column', 'row']}
            css={contentList}
          >
            <Column>
              <div css={contentIntems}>
                <div css={contentIntem}>
                  <div css={contentCircle}>1</div>
                  <Paragraph>Um visitante acessa o seu serviço durante um ataque de DDoS.</Paragraph>
                </div>
                <div css={contentIntem}>
                  <div css={contentCircle}>2</div>
                  <Paragraph>O alto tráfego de entrada direcionado para o IP afetado será desviado para a segurança global sob a nuvem limpa mais próxima.</Paragraph>
                </div>
                <div css={contentIntem}>
                  <div css={contentCircle}>3</div>
                  <Paragraph>O tráfego regional será limpo na nuvem de Miami, bem próximo ao nosso Data Center, a fim de prevenir latência.</Paragraph>
                </div>
              </div>
            </Column>
            <Column>
              <div css={contentIntems}>
                <div css={contentIntem}>
                  <div css={contentCircle}>4</div>
                  <Paragraph>O Tráfego limpo será entregue ao roteador o qual controla todo o tráfego do servidor.</Paragraph>
                </div>
                <div css={contentIntem}>
                  <div css={contentCircle}>5</div>
                  <Paragraph>Tempo de resposta efetivo será retornado ao visitante diretamente do IP do servidor.</Paragraph>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={globalCloud}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
          >
            <Column
              width={[1]}
              mb={50}
            >
              <H5>Segurança global cloud da Hostdime Brasil.</H5>
              <img src={Ilustration2} />
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Proteção DDoS Cloud' />
      <PageTemplate
        title='Proteção DDoS Cloud'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
